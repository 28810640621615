/**This file contains a constant object that has properties that are necessary
 * for the build found in the `development` configuration in `angular.json`.
 */
export const environment = {
	production: false,
	// tokenUrl: 'https://corpqa.sts.ford.com/adfs/oauth2/authorize',
	// clientId: 'urn:angularservice:pcf:dev',
	// resource: 'urn:angularservice:weblogin:dev',
	// redirectUrl: 'https://ngppwebdev.apps.pp01i.edc1.cf.ford.com/'


	tokenUrl: 'https://corpqa.sts.ford.com/adfs/oauth2/authorize',
	clientId: 'urn:ngpp:clientid:web_ngpp_dev:dev',
	resource: 'urn:ngpp:resource:web_ngpp_dev:dev',
 // redirectUrl: 'https://ngppwebdev.apps.pp01i.edc1.cf.ford.com/'

	// redirectUrl: 'wwwdev.cfms.ford.com'
	// clientId: 'urn:ssotest:clientid:web_devenablement:qa',
	// resource: 'urn:ssotest_web',
    // clientId: 'urn:ngpp:clientid:web_ngpp_test_web:dev',
	// resource: 'urn:ngpp:resource:web_ngpp_test_web:dev',
	redirectUrl: 'https://wwwdev.nggpp.gcp.ford.com',
	// include development api base url string here as a property

	appBaseAPIUrl:'https://api-dev.is.ford.com/nggpp/api/v1',
	nfsBaseAPIUrl:'https://api-dev.is.ford.com/nggppfile/api/v1/bucket',
	nfsBasePath:'PropImages/',

	loginUrl:'https://wwwdev.nggpp.gcp.ford.com'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
